$fa-font-path: "../webfonts";
$fa-font-family: 'Font Awesome 5 Free';

$theme-colors: (
    "primary": #e9c4c7,
    "secondary": #e1b7bb,
);

$asty: 'AstyCFStd-Book';
$astyM: 'AstyCFStd-Medium';
$astyB: 'AstyCFStd-Bold';
$astyEB: 'AstyCFStd-ExtraBold';

$audrey: 'Audrey-Normal';
$audreyM: 'Audrey-Medium';
$audreyB: 'Audrey-Bold';
$adam: 'AdamCGPro';
$cremona: 'Cremona';
$salome: 'Salome';
$black_friday: 'Black Friday';

$primary-color: #e9c4c7;
$secondary-color: #e1b7bb;
$white: #fff;
$black: #0b0b0b;
$silver: #efefef;
$lightpink: #f6eae8;
$lightbrown: #997376;
$aboutgrey: #eae8e8;
$contactpink: #fed8db;

%content {
    content: '';
    position: absolute;
}

%bold {
    font: {
        family: $astyB;
    }
}

%font-awesome {
	color: $black;
    font: {
        family: "Font Awesome 5 Free";
        weight: 900;
        size: 15px;
    }
}

%smooth-hover {
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}