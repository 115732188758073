#product-category{
    #mfilter-content-container {
        .product-layout {
            .product-thumb {
                position: relative;               
                .image{
                    text-align: center;
                    border: 1px solid #C9C9C9;
                    margin-bottom: 10px;
                }
                .details {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 20px;
                    .caption {
                        h4 {
                            vertical-align: middle;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            a {
                                color: $black;
                                font-size: 16px;
                                font-family: $astyM;
                                white-space: nowrap;
                            }
                        }
                    }
                    .wishlist-button {
                        display: flex;
                        justify-content: flex-end;
                        button {
                            border: none;
                            background: transparent;
                            .heart-hover {
                                display: none;
                            }
                            &:hover {
                                .heart-normal {
                                    display: none;
                                }
                                .heart-hover {
                                    display: block;
                                }
                            }
                        }
                    }
                    @include media-breakpoint-down(md) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                    @include media-breakpoint-down(sm) {
                        .wishlist-button {
                            display: none;
                        } 
                    }
                }
                .button-group{
                    button{
                        width: 100%;
                        border-radius: 90px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid $black;                        
                        transition: all .4s;
                        background: transparent;
                        padding: 10px;
                        @include media-breakpoint-down(md) {
                            padding: 5px;
                        }
                        &:hover {
                            background: $black;
                            .add-to-cart {
                                color: $white;
                            }
                            .price{
                                color: $white;
                                .price-normal, .price-old, .price-new{
                                    color: $white;
                                    text-decoration-color: $white;
                                }
                            }
                        }
                        .add-to-cart {
                            color: $black;
                            font-family: $astyB;
                            font-size: 15px;
                            @include media-breakpoint-down(md) {
                                font-size: 13px;
                            }
                        }
                        .price {
                            margin-bottom: 0;
                            display: inherit;
                            .price-normal, .price-new {
                                font-family: $astyB;
                                color: $black;
                                font-size: 15px;
                            }
                            .price-old{
                                font-family: $astyB;
                                color: $black;
                                font-size: 15px;
                                text-decoration: line-through;   
                                text-decoration-color: red;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.badges { 
    position: absolute;
    /*top: 0;*/
    /*right: 0;*/
    z-index: 1;
} 
.badged {
    img {
        transition: transform .7s ease-in-out;
        @include media-breakpoint-down(xl) {
            max-width: 65px;
        }
        @include media-breakpoint-down(sm) {
            max-width: 50px;
            // display: none;
        }
    }
    &:hover {
        img {
            transform: rotate(360deg);
        }
    }
}
body {
    &[class^="product-"] {
        .product-layout {
            margin-bottom: 6rem;
            @include media-breakpoint-down(lg) { 
                margin-bottom: 3rem;
            }
            @include media-breakpoint-down(sm) { 
                margin-bottom: 2rem;
            }
        }
    }
    &[class^="product-search"] {
        .badges {
            top: auto;
            right: auto;
        }
    }
}
.filter-column {
    position: fixed;
    width: 600px;
    height: 100vh;
    top: 0;
    right: -600px;
    z-index: 999;
    background: $lightpink;
    padding-left: 4rem;
    padding-right: 4rem;
    transition: all .6s;
    @include media-breakpoint-down(lg) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @include media-breakpoint-down(md) {
        padding-left: 15px;
        padding-right: 15px;
    }
    &.show {
        right: 0;
    }
    .box-heading {
        display: none;
    }
    .mfilter-heading-content {
        .mfilter-heading-text {
            margin-left: unset;
            text-align: center;
            span{
                font-size: 21px;
                color: $black;
                font-family: $asty;
                text-align: center;
            }
            i{
                display: none;
            }    
        }
    }
    .mfilter-content {
        border: none;
    }
    .mfilter-heading {
        background: transparent;
        .mfilter-head-icon{
            display: none;
        }
    }
    .mfilter-image ul{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .mfilter-light-theme .mfilter-heading-content {
        border-bottom: 1px solid $primary-color;
    }
    select {
        &#input-sort {
            margin-top: 10%;
            padding: 10px 15px;
            font-size: 15px;
            font-family: $astyB;
            border: none;
            border-radius: 90px;
            background-color: $white;
        }
        &#input-limit {
            padding: 10px 15px;
            font-size: 15px;
            font-family: $astyB;
            background-color: $white;
            border: none;
            border-radius: 90px;
            margin-top: 20px;
        }
    }
}
.category_options {    
    margin: {
        top: 3em;
        bottom: 3em;
    }
    @include media-breakpoint-down(sm) { 
        margin-top: 1em;
        margin-bottom: 2em;
    }
    .form-control {
        border: none;
    }
    .category_options_left {
        .cont {
            background: #F2F2F2;
            border-radius: 31px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 11px 25px;
            width: 100%;
        }
        ul {
            margin-bottom: 0;
            padding: 0;
            li {
                font-size:17px;
                color: $black;
                font-family: $asty;
                a {
                    font-size:17px;
                    color: $black;
                    font-family: $asty;
                }

                @include media-breakpoint-down(md) { 
                    padding: 0;
                    font-size: 15px; 
                    a {
                        font-size: 15px; 
                    }
                }
            }
        }
    }
    .category_options_right{
        .cont {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $lightpink;
            border-radius: 31px;
            padding: 10px 25px;
            width: 100%;
            cursor: pointer;
            position: relative;

            @include media-breakpoint-down(md) { 
                margin-top: 10px;
            }

            .filter-icon {
                position: absolute;
                right: 5%;
            }
        }
    }
}
.upper-part{
    padding: 0;
    margin-bottom: -46px;
    img {
        -webkit-transform: scaleX(-1) scaleY(-1);
        transform: scaleX(-1) scaleY(-1);
    }
}
.banner-category{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 480px;
    background: linear-gradient(167deg, rgba(246,234,232,1) 0%, rgba(233,196,199,1) 100%);
    padding-bottom: 40px;
    padding-top: 40px;
    clip-path: polygon(0 10%, 100% 0, 100% 88%, 0 100%);
    margin-bottom: 4em;

    @include media-breakpoint-down(lg) { 
        display: none;
    }

    .middle-part{       
        display:flex;
        flex-wrap:wrap;
        flex-direction:column;       
        align-items:center;
        justify-content: center;
        .title-banner{
            display:flex;
            align-items:center;
            .left-title,.right-title{
                font-size:50px;
                font-family:$astyM;
                color:$white;
            }
            .image-title{
                margin-left:10px;
                margin-right:10px;
            }
        }
        .text-banner{
            margin-top:20px;
            text-align:center;
            p {
                color: $lightbrown;
                font-size:16px;                
            }
        }
    }
}
.lower-part{
    margin-top:-42px;
    margin-bottom:8rem;
    padding:0;
}
.pagination{
    margin-bottom: 5em;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;

    li {
        padding: 10px;
        border-bottom: 2px solid;
        a {
            &:focus {
                color: $primary-color;
            }
            &:hover {
                color: $primary-color;
            }
        }
        span {
            &:focus{
                color: $primary-color;
            }
            &:hover{
                color: $primary-color;
            }
        }
        &:first-child {
            a, span {
                border: {
                    top-left-radius: 0;
                    bottom-left-radius: 0;
                }
            }
        }
        &:last-child {
            a, span {
                border: {
                    top-right-radius: 0;
                    bottom-right-radius: 0;
                }
            }
        }    
        a, span {
            font-weight: 600;
            color: #888;
            color: #666;
            /*border: 2px solid #fab7bf;*/
            padding: 10px;
            line-height: 1.42857143;
        }
    }

    .active {
        a, span {
            color: $primary-color;
            color: #645152;
            &:focus, &:hover {
                background-color: transparent;
                border-color: $primary-color;
                color: $primary-color;
                color: #645152;
            }
        }
    }
}
.close-mfilter{
    cursor: pointer;
    margin-top: 20px;
}
