body {
    counter-reset: section;
    scroll-behavior: smooth;
    color: $black;
    font: {
        size: 18px;
        family: $asty;
    }

    @include media-breakpoint-down(md) { 
        font-size: 16px;
    }

    &.menu_opened {
        overflow: hidden !important;
    }

    a {
        text-decoration: none;
        color: $black;
        @extend %smooth-hover;

        &:hover, &:focus {
            outline: 0;
            opacity: 0.8;
            color: $black;
        }
    }

    &[class^="information-"],
    &[class^="product-manufacturer"],
    &[class^="account-"] {
        background-color: $lightpink;
    }
    &[class^="information-contact"] {
        background-color: $contactpink;
    }
    &[class^="information-information-4"] {
        background-color: $aboutgrey;
    }
    &[class^="product-manufacturer-info-"] {
        background-color: $white;
    }
}
h1, h2, h3, h4, h5, h6 {
	color: #444;
}
button, .btn, input {
    &:hover, &:focus, &:active {
        outline: 0;
        box-shadow: none;
    }
}
.table-bordered>:not(caption)>*>* {
    vertical-align: baseline;
}
legend {
	font-size: 18px;
    font-family: $astyM;
    margin-top: 1em;
	padding: 7px 0;
}
label {
	font-size: 15px;
	font-weight: normal;
}
.text-brown {
    color: $lightbrown;
}
.text-danger {
    color: #cd1b1b;
    font-size: 15px;
}
.bg-silver {
    background-color: $silver;
}
.bg-light {
    background-color: #FAFAFA;
}
.adam {
    font-family: $adam;
}
.cremona {
    font-family: $cremona;
}
.salome {
    font-family: $salome;
}
.bold {
    @extend %bold;
}
input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    margin: 0 8px 0 0;
    font: inherit;
    color: $primary-color;
    width: 1em;
    height: 1em;
    border: 0.15em solid $primary-color;
    border-radius: 0.35em;
    transform: translateY(-0.075em);
    display: inline-grid;
    place-content: center;
    &::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em $primary-color;
        background-color: CanvasText;
    }
    &:checked::before {
        transform: scale(1);
    }
    &:focus {
        outline: max(2px, 0.15em) solid $primary-color;
        outline-offset: max(2px, 0.15em);
    }
    &:disabled {
        color: #959495;
        cursor: not-allowed;
    }
}
input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    margin: 0 8px 0 0;
    font: inherit;
    color: $primary-color;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid $primary-color;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: inline-grid;
    place-content: center;
    &::before {
        content: "";
        width: 0.6em;
        height: 0.6em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em $primary-color;
        background-color: CanvasText;
    }
    &:checked::before {
        transform: scale(1);
    }
    &:focus {
        outline: max(2px, 0.15em) solid $primary-color;
        outline-offset: max(2px, 0.15em);
    }
}
.form-check-input:checked {
    background-color: $white;
    border-color: $primary-color;
    color: $primary-color;
    &:focus {
        box-shadow: none;
    }
}
section {
    &[class^="account-"], &[class^="success-"] {
        margin: 2em auto 7em;
        .breadcrumb {
            margin-bottom: 5em;
        }
        .alert {
            top: 7em;
        }
        h2, h1 {
            font-family: $astyB;
            font-size: 19px;
        }
        p {
            font-size: 16px;
        }
        .list-unstyled {
            font-size: 17px;
            color: $primary-color;
            li {
                &::before {
                    padding-right: 5px;
                    font-size: 22px;
                    content: "\25CF";
                }
            }
        }
        .btn-info {
            border-radius: 0;
            border: 2px solid $lightbrown;
        }
        .btn-danger {
            border-radius: 0;
            border: 2px solid red;
        }
        .list-group {
            border-radius: 0;
            .list-group-item {
                border: 2px solid $primary-color;
                margin: 1px 0;
            }
            .list-group-item+.list-group-item {
                border-top-width: 2px;
            }
        }
        @include media-breakpoint-down(md) {  
            margin-bottom: 2em;
            .breadcrumb {
                margin-bottom: 3em;
            }
            .list-group {
                margin-top: 1em;
            }
        }
    }
}
.dwhite-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000 - 10;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: .7);
    transition: opacity .3 ease-in;
    
    &.backdrop-hidden {
        width: 0;
        height: 0;
        left: 0;
        opacity: 0;
        overflow: hidden;
    }
}
.breadcrumb {
    padding: 0 15px 12px 0;
    font-size: 14px;
    @include media-breakpoint-between(xs, md) {
        display: flex;
        flex-flow: row nowrap;
        max-width: 100%;
        overflow: scroll;

        li {
            font-size: 14px;
            padding: 5px 0 5px 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }
    }
    li {        
        &:after {
            content: '';
            display: inline-block;
            width: 7px;
            height: 7px;
            margin: 1px 7px;
            border-radius: 7px;
            background-color: $black;
        }
        &:last-child {
            &:after {
                background-color: transparent;
            }
        }
        a {
            color: $black;
            font-size: 1em;
        }
    }
}
.alert {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    padding: 15px 5px;
    border: 3px solid $primary-color;
    border-radius: 0;
    z-index: 1;
    background-color: #fff;
    a {
        font-family: $astyM;
    }
    .close {
        padding: 0 5px;
        line-height: 1;
        font-size: 18px;
        border: 1px solid #bbbbbb;
        margin-left: 1em;
        background-color: $white;
    }
}
.owl-item {
	.product-layout {
		.product-thumb {
			img {
				display: inline-block;
			}
		}
	}
}
#mp-menu {
    display: none;
    &.mm-menu_opened {
        display: block;
        z-index: 6;
    }
}
.mm-wrapper_opened {
    .mm-menu_fx-menu-slide {
        transform: translate(-100%, 0);
        transform: translate3d(-100%, 0, 0);
    }
}
.mm-wrapper_opening {
    .mm-menu_fx-menu-slide {
        transform: translate(0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.mm-btn {
    &.mm-btn_prev {
        &:before {
            border-color: #000;
            width: 12px;
            height: 12px;
        }
        &.mm-navbar__btn {
            left: 0;
        }
    }
}
.required .col-form-label:before {
    content: '* ';
    color: #F00;
    font-weight: bold;
}
.btn-remove {
    background: $red;
    overflow: hidden;
    outline: none;
    box-shadow: none;
    .fa {
        font-weight: 400;
        color: #fff;
    }
    &:hover {
        background-color: #db5f6b;
    }
}
.toggle-password {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    position: absolute;
    right: 0;
    bottom: 1em;
    z-index: 1;
    float: right;
    display: none;
    .fas {
        cursor: pointer;        
    }
}
.form-control {
    border-radius: 0;
    border: 0;
    border-bottom: 4px solid #e9c5c7;
    background-color: transparent;
    color: $black;
    &:focus {
        border-color: #d7cacb;
        box-shadow: 0 0 0 0.1rem rgba(255, 188, 22, 0.25);
    }
}
.buttons {
    margin: {
        top: 2em;
        bottom: 4em;
    }
}
.btn-default {
    border-radius: 0;
    border: 2px solid $primary-color;
    color: $black;
    font-weight: 600;
    &:hover {
        background-color: $primary-color;
        color: $black;
    }
}
.btn-primary {
    border-radius: 0;
    font-weight: 600;
    overflow: hidden;
    position: relative;
    &:after {
        background: $white;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: -10;
    }
    &:hover {
        background-color: $contactpink;
        &:after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
}
#product-manufacturer-list {
    .subheading {
        border-bottom: 1px solid #eee;
        margin-top: 0;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        color: #5c5746;
        padding: 15px 0 7px;
    }
    .brand-list {
        border: 1px solid #948a68;
        /*padding: 5px;*/
        border-radius: 20px;
        margin-bottom: 20px;
        background-color: #fff;     
        padding-bottom: 10px;  
    }
    .bname {
        margin: 0 0 6px;
        padding: 7px 12px;
        color: #444;
        background: none repeat scroll 0 0 #e9c5c7;
        background: linear-gradient(90deg, #F6EAE8, #e9c5c7);
        font-size: 16px;
        font-weight: 700;
        border-top: {
            right-radius: 20px;
            left-radius: 20px;
        }
    }    
    .brand-img {
        border-radius: 50rem;
        border: 1px solid $silver;
    }
    .brands-content {
        font-family: $cremona;
        font-size: 20px;
        font-weight: 600;
        a {
            &:hover {
                color: #948a68;
            }
        }
    }
}
.input--hoshi {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    vertical-align: top; 
    overflow: hidden;
    &.input-group {
        float: left;
    }
    .input {
        &__field {
            position: relative;
            display: block;
            padding: 0.8em;
            border: none;
            border-radius: 0;
            font-weight: bold;
            -webkit-appearance: none;
            width: 100% !important;

            &:focus {
                outline: none;
                box-shadow: none;
                background-color: transparent;
            } 
        }
        &__label {
            display: inline-block;
            float: right;
            padding: 0 1em;
            width: 40%;
            color: #5b5b5b;
            font-weight: bold;
            line-height: .5;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            -webkit-touch-callout: none;
            user-select: none;

            &-content {
                position: relative;
                display: block;
                padding: 1.6em 0;
                width: 100%;
            }
        }
        &__field--hoshi {
            margin-top: 1em;
            padding: 0.85em 0.15em;
            width: 100%;
            background: transparent;
            color: $black;
        }
        &__label--hoshi {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 0.25em;
            width: 100%;
            height: calc(100% - 1em);
            text-align: left;
            pointer-events: none;

            &::before, &::after {
                @extend %content;
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% - 10px);
                border-bottom: 3px solid $primary-color;
            }

            &::after {
                margin-top: 2px;
                transform: translate3d(-100%, 0, 0);
                border-bottom: 2px solid $primary-color;
                transition: transform 0.3s;
            }
        }
        &__label-content--hoshi {
            position: absolute;
        }
    }
}
.input__field--hoshi:focus + .input__label--hoshi::after,
.input--filled .input__label--hoshi::after {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi,
.input--filled .input__label-content--hoshi {
    -webkit-animation: anim-1 0.3s forwards;
    animation: anim-1 0.3s forwards;
}

.row-slider{
    padding-top:50px;
    background:#efefef;
    padding-bottom:50px;
}

.banners-home{
    margin:40px auto;
    max-width:1801px;
}

.videobanner{
    
    display:flex;
    align-items:center;
    justify-content:center;
    padding-left:1rem;
   
    .video-banner-wrapper{
      background:#e9c4c7;
     
        display:flex;
        align-items:center;
        justify-content: center;
        flex:0 0 auto;
        width:100%;
        border-radius:9px;
    }
}

.videobanner{

  
    .video-banner-wrapper{
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        height:100%;
        iframe{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            margin:0 auto; 
        }
    }
}


/* valentine */
#valentine, #snowflake, 
#special-effect {
	position: fixed;
    inset: 0px;
    z-index: 1000;
    pointer-events: none;
}
.flakes {
    position: absolute;
    width: 96px;
    height: 57px;
    z-index: 2;
    /*background: url('./../image/valentine.png') no-repeat 100%;*/
    background: url('../image/percentage.png') no-repeat 100%;
}
#special-effect {
	 display: none;
}
.common-home #special-effect {
	display: block;
}

/*
.blackfriday-top{
    background:#000;
    color:#fff;
    text-align:center;
    position:relative;
    padding-top: 10px;
    padding-bottom:10px;
    p,a{
        color:#fff;
        margin-bottom:0;
        font-size:18px;
        font-family: $black_friday;
        letter-spacing:10px;
        &:hover{
            color:#fff;
        }
        span{
            font-size:24px;
        }
    }
    .explore-friday{
        position: absolute;
        right: 13px;
        top: -5px;
        .explore{
            border-radius: 24px;
            padding: 10px 28px;
            background: #2e2e2e;
            color: #fff;
            font-family: $black_friday;
        }
    }
}


.espa{
    top:110px;
}

.black-friday-sticker {
    position: fixed;
    width: 200px;
    height: 200px;
    background: #f6d0df;
    right: 20px;
    bottom: 4%;
    border-radius: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    a{
        display:flex;
        align-items:center;
        justify-content: center;
        text-decoration: none;
        flex-direction: column;
        align-items:center;
        justify-content:center;
        width:100%;
        height:100%;
        p{
            margin-bottom:0;
            text-align:center;
        }
        .text-bold{
            font-family: $astyEB;
        }
        .text-norm{
            font-family: $astyM;
        }
    }
    img{
        filter: invert(1);
    }


}


.category-page{
    background:#000;
    .header--wrap--menu .nav-link{
        color:#fff;
    }
    .logo-header img {
        filter: brightness(0) invert(1);
    }

    .header--wrap--info{
        background-color: #DB0E0E;
        p{
            color:#fff;
        }
    }

    .header--wrap--menu img{
        filter: brightness(0) invert(1);
    }
    .category_options .category_options_left .cont{
        background:    #292929;
    }

    .category_options .category_options_left ul li a{
        color:#fff;
    }

    .category_options .category_options_right .cont{
        background:    #292929;
    }
    .category_options .category_options_right .cont{
       span{
        color:#fff;
       }
       img{
        filter:invert(1);
       }
    }
    .breadcrumb li:after{
        background-color:#fff;
    }

    #product-category #mfilter-content-container .product-layout .product-thumb .button-group button{
        border: 1px solid #fff;
        color:#fff;
    }

    #product-category #mfilter-content-container .product-layout .product-thumb .button-group button .add-to-cart{
        color:#fff;
    }
    #product-category #mfilter-content-container .product-layout .product-thumb .button-group button .price{
        color:#fff;   
    }
    #product-category #mfilter-content-container .product-layout .product-thumb .button-group button .price .price-new, #product-category #mfilter-content-container .product-layout .product-thumb .button-group button .price .price-normal{
        color:#fff;
    }

    #product-category #mfilter-content-container .product-layout .product-thumb .details .caption .h4 a, #product-category #mfilter-content-container .product-layout .product-thumb .details .caption h4 a{
        color:#fff;
    }

    .wishlist-button img:first-child {
        filter: invert(1);
    }

    .wishlist-button img:nth-child(2) {
        filter: brightness(0) invert(1);
    }

    .pagination .active a, .pagination .active span{
        color:red;
    }

    .pagination li a, .pagination li span{
        color:#fff;
    }
    #product-category #mfilter-content-container .product-layout .product-thumb .button-group button .price .price-old{
        color:#fff;
    }
}

*/


@import "module";
@import "product";
@import "information";
@import "about";
@import "contact";
@import "category";
