.home {
    &--banners {
        #banner0, #slide0 {
            display: block;
        }
        #banner1, #slide1 {
            display: none;
        }
        @include media-breakpoint-down(lg) {
            #banner0, #slide0 {
                display: none;
            }
            #banner1, #slide1 {
                display: block;
                margin-top: 1em;
            }
        }
        @include media-breakpoint-down(sm) {
            .homeslide {
                order: 1;
            }
            .sidebanners {
                order: 6;
            }            
            .videobanner {
                order: 12;
            }
        }
    }  
    &--about {
        background-color: $lightpink;
        .imganimation {
            position: absolute;
            right: 0;
            left: 0;
            margin: 0 auto;
            width: max-content;
            width: fit-content;
            text-align: center;
            @include media-breakpoint-down(xl) {
                img {
                    max-width: 250px;
                }     
            }
            @include media-breakpoint-down(md) {
                position: relative;
                img {
                    max-width: 200px;
                }
            }
        }
        .main-title {
            font-size: 50px;
            @include media-breakpoint-down(xl) {
                font-size: 35px;
            }
        }
        .sub-title {
            font: {
                size: 22px;
                family: $astyB;
            }
            @include media-breakpoint-down(xl) {
                font-size: 16px;
            }
        }
        .main-text {
            font-size: 14px;
        }
        .main-btn {
            .btn-about {
                position: relative;
                display: inline-block;
                cursor: pointer;
                outline: none;
                border: 0;
                vertical-align: middle;
                text-decoration: none;
                background: transparent;
                padding: 0;
                font-size: inherit;
                font-family: inherit;
                &.btn {
                    width: 12rem;
                    height: auto;
                    .circle {
                        transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                        position: relative;
                        display: block;
                        margin: 0 auto;
                        width: 3rem;
                        height: 3rem;
                        background: #fff;
                        border-radius: 1.625rem;
                        .icon {
                            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            background: $black;
                            &.arrow {
                                transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                                left: 0.625rem;
                                width: 1.125rem;
                                height: 0.125rem;
                                background: none;
                                &::before {
                                    position: absolute;
                                    content: '';
                                    top: -0.25rem;
                                    right: 0.0625rem;
                                    width: 0.625rem;
                                    height: 0.625rem;
                                    border-top: 0.125rem solid $black;
                                    border-right: 0.125rem solid $black;
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }
                    .button-text {
                        transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        padding: 0.75rem 0;
                        margin: 0 0 0 1.85rem;
                        color: $black;
                        font-size: 14px;
                        font-family: $astyM;
                        line-height: 1.6;
                        text-align: center;
                        text-transform: uppercase;
                        opacity: 0;
                    }
                    &:hover {
                        .circle {
                            width: 100%;
                            .icon.arrow {
                                background: $black;
                                transform: translate(1rem, 0);
                            }
                        }
                        .button-text {
                            color: $black;
                            opacity: 1;
                        }
                    }
                    @include media-breakpoint-down(lg) {
                        .circle {
                            width: 100%;
                            .icon.arrow {
                                background: $black;
                                transform: translate(1rem, 0);
                            }
                        }
                        .button-text {
                            color: $black;
                            opacity: 1;
                        }
                    }
                }
            } 
        }
    }
    &--tabs {
        max-width: 1717px;
        margin: 6em auto 8em;
        .nav-pills {
            .nav-link {
                font-family: $salome;
                color: #D8D8D8;
                color: #939393;
                font-size: 31px;
                background-color: transparent;
                @include media-breakpoint-down(lg) {
                    font-size: 27px;
                }
                &.active {
                    background-color: transparent;
                    color: $black;
                }
            }
        }
        .badges { 
            position: absolute;
            z-index: 99;
            top: auto;
            right: auto;
        } 

        @include media-breakpoint-down(md) {
            .nav-pills {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
                max-width: 100%;
                overflow: scroll;
                /* margin-left: 4em; */
                padding-bottom: 10px;
                .nav-item { 
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100%;
                    margin-left: 5px;
                }
            }
        }
    }
}
.owl-carousel {
    .owl-nav {
        text-align: center;
        font-size: 2em;
        button {
            &.owl-next, &.owl-prev {
                top: 50%;
                position: absolute;
            }
            &.owl-next {
                right: 0;
            }
            &.owl-prev {
                left: 0;
            }
        }
    }
}
.instagram {
    .item {
        max-height: 300px;
        overflow: hidden;
        .fa {
            top: 40%;
            font-size: 40px;
            color: $contactpink;
            &:before {
                color: $contactpink;
            }
        }
    }
}
.categories--wrapper {
	background-color: $lightpink;
	padding: 7em 6rem;
	margin: 5em auto;
    clip-path: polygon(0 10%, 100% 0, 100% 88%, 0 100%);
	.caption {
		text-align: left;
        color: $black;
		font: {
			family: $astyB;
			size: 25px;
		}
	}
}
.info--wrapper {
	.container--wrapper {
		background-color: $silver;
		padding: 2em;
		text-align: center;
		border-radius: 20px;
	}
	.info-title {
		text-transform: uppercase;
		font: {
			size: 50px;
			family: $astyB;
		}
        @include media-breakpoint-down(xl) {
            font-size: 35px;
        }
        @include media-breakpoint-down(md) {
            font-size: 20px;
            margin: 1em auto;
        }
	}
	.info-text {
		font-size: 16px;
		color: $lightbrown;
        color: #866467;
	}
	.info-list {
		.nav-link {
			border-radius: 20px;
			border: 1px solid $white;
			color: $black;
			font: {
				size: 16px;
				family: $astyM;
			}
			&:hover {
				background-color: $white;
			}
		}        
        @include media-breakpoint-down(lg) {
            .nav-link { background-color: $white; }
        }
        @include media-breakpoint-down(md) {
            display: block;
            .nav-link { margin-top: 5px; }
        }
	}
}
.instagram--wrapper {
	.instagram-title {
		text-transform: uppercase;
		font-size: 50px;		
        @include media-breakpoint-down(md) {
            font-size: 33px;
        }
	}
	.instagram-subtitle {
		font-size: 23px;
	}
    ul {
        .list-inline-item:not(:last-child) {
            margin: 0;
        }
    }
    .btn-insta {
        position: relative;
        display: inline-block;
        cursor: pointer;
        outline: none;
        border: 0;
        vertical-align: middle;
        text-decoration: none;
        background: transparent;
        padding: 0;
        font-size: inherit;
        font-family: inherit;
        text-align: center;
        margin: 0 auto;
        display: block;
        &.btn {
            width: 15rem;
            height: auto;
            .circle {
                transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                position: relative;
                display: block;
                margin: 0 auto;
                width: 3rem;
                height: 3rem;
                background: $primary-color;
                border-radius: 1.625rem;
                .icon {
                    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    background: $black;
                    &.arrow {
                        transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                        right: 1.215rem;
                        width: 1.125rem;
                        height: 0.125rem;
                        background: none;
                        &::after {
                            position: absolute;
                            content: '';
                            top: -0.25rem;
                            right: 0;
                            width: 0.625rem;
                            height: 0.625rem;
                            border-top: 0.125rem solid $black;
                            border-right: 0.125rem solid $black;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            .button-text {
                transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 0.75rem 0;
                margin: 0 1.85rem 0 0;
                color: $black;
                font-size: 14px;
                font-family: $astyM;
                line-height: 1.6;
                text-align: center;
                text-transform: uppercase;
                opacity: 0;
                z-index: 1;
            }
            &:hover {
                .circle {
                    width: 100%;
                    .icon {
                        &.arrow {
                            background: $black;
                            transform: translate(-0.5rem, 0);
                        }
                    }
                }
                .button-text {
                    color: $black;
                    opacity: 1;
                }
            }
            @include media-breakpoint-down(lg) {
                .circle {
                    width: 100%;
                    .icon {
                        &.arrow {
                            background: $black;
                            transform: translate(-0.5rem, 0);
                        }
                    }
                }
                .button-text {
                    color: $black;
                    opacity: 1;
                }
            }
        }
    } 
}
.login-aside {
    height: 100%;
    width: 28%;
    position: fixed;
    z-index: 20100;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background-color: $lightpink;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all .25s ease-in-out;
    border: 2px solid $lightpink;

    @include thin-scollbar;

    &.show {
        transform: translateX(0);
    }

    .fas {
        font-size: 20px;
    }

    &__header {
        padding: 4em 2em 2em;
        display: flex;
        flex-flow: row wrap;

        h2 {
            color: black;
            font-size: 14px;
            font-family: $astyB;
            line-height: 17px;
            &:after {
                @extend %content;
                background: $lightbrown;
                height: 2px;
                width: 85%;
                margin-top: 1.8em;
                left: 2rem;
            }
        }

        img {
            width: 25px;
            height: 25px;
        }
    }

    &__form {
        margin: 7vh 2em 2em;

        ul {
            color: $primary-color;
            li {
                line-height: 2;
                font-size: 16px;
                &::before {
                    padding-right: 5px;
                    font-size: 22px;
                    content: "\25CF";
                }
            }
        }
    }

    &__footer {
        padding: 0 2em;
        margin-top: 5vh;

        .control-label {
            color: $black;
            font-size: 15px;
            font-family: $astyM;
            margin-bottom: 2em;
        }

        .label {
            color: $lightbrown;
            color: #7e5e60;
            font-size: 14px;
            font-family: $astyM;
        }

        .register-btn {
            font-size: 15px;
            line-height: 34px;
            color: $black;
            padding: 5px 18px;
            border-radius: 13px;
            border-color: $white;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                color: $white;
                border-color: $primary-color;
                background-color: $primary-color;
            }
            &:focus {
                border-color: $primary-color;
                background-color: $primary-color;
                opacity: 0.8;
            }
        }

        .register-link {
            margin-top: 12.5px;
            color: black;
            font-size: 16px;
            display: block;
            text-align: center;

            &:hover {
                color: $black;
            }
        }
    }
    &__bottom {
    	.upper {
		    position: absolute;
		    bottom: 0;
            right: 0;
            left: 0;
    	}
    }
    @include media-breakpoint-down(lg) {
        .login-aside {
            width: 100%;
            &__header {
                &.mobile {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 16px 7.94%;
                    border-bottom: 2px solid white;
                    margin-bottom: 0;

                    .title {
                        color: black;
                        font-size: 5vw;
                        line-height: 37px;
                    }

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 15px;
                        background-color: white;
                        color: black;
                        width: 56px;
                        .fa-angle-left {
                            font-size: 18px;
                        }
                        img {
                            width: auto;
                            height: 18px;
                        }
                    }
                }
            }
            &__form {
                margin-top: 7vh;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .login-aside {
            &__header {
                padding-left: 0;
                padding-right: 0;
            }
            &__footer {
                padding-left: 12px;
                padding-right: 12px;
            }
        }
    }
}
.instagram-media {
    p, a, div, * {
        color: #000 !important;
    }
}
@media screen and (max-width: 1679px) {
    .categories--wrapper {
        padding: 7em 15px;
    }
}
@media screen and (max-width: 1555px) {
    .home {
        &--about {
            .main-title {
                font-size: 40px;
            }
            .sub-title {
                font-size: 18px;
            }
        }
    }
    .categories {
        &--wrapper {
            padding: 7em 2rem;
        }
    }
}
@media screen and (max-width: 1366px) {
    .home {
        &--tabs {
            margin: 4em auto 6em;
        }
    }
    .categories {
        &--wrapper {
            padding: 5em 15px;
        }
    }
}
@media screen and (max-width: 1295px) {
    .categories {
        &--wrapper {
            img {
                max-width: 280px;
            }
        }
    }
}
@media screen and (max-width: 1199px) {
    .categories {
        &--wrapper {
            img {
                max-width: 250px;
            }
        }
    }
}
@media screen and (max-width: 1045px) {
    .categories {
        &--wrapper {
            img {
                max-width: 200px;
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .home {
        &--tabs {
            .product-layout {
                margin-bottom: 1em;
            }
        }
    }
}
@media screen and (max-width: 850px) {
    .categories {
        &--wrapper {
            img {
                max-width: 180px;
            }
            .caption {
                font-size: 19px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .home {
        &--tabs {
            margin: 2em auto 4em;
            .nav-pills {
                .nav-link {
                    font-size: 16px;
                    border: 1px solid #D8D8D8;
                    border-radius: 20px;
                    &.active {
                        border-color: #000;
                    }
                }
            }
        } 
    }
}
@media screen and (max-width: 576px) {
    .categories {
        &--wrapper {
            img {
                max-width: 150px;
            }
        }
    }
}