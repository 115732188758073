@font-face {
	font-family: $astyB;
	src: url('../fonts/AstyCfStd/AstyCFStd-Bold.otf');
}
@font-face {
	font-family: $astyEB;
	src: url('../fonts/AstyCfStd/AstyCFStd-ExtraBold.otf');
}
@font-face {
	font-family: $astyM;
	src: url('../fonts/AstyCfStd/AstyCFStd-Medium.otf');
}
@font-face {
	font-family: $asty;
	src: url('../fonts/AstyCfStd/AstyCFStd-Book.otf');
}
@font-face {
	font-family: $audrey;
	src: url('../fonts/Audrey/Audrey-Normal.otf');
}
@font-face {
	font-family: $audreyB;
	src: url('../fonts/Audrey/Audrey-Bold.otf');
}
@font-face {
	font-family: $audreyM;
	src: url('../fonts/Audrey/Audrey-Medium.otf');
}
@font-face {
	font-family: $adam;
	src: url('../fonts/AdamCGPro.otf');
}
@font-face {
	font-family: $cremona;
	src: url('../fonts/Cremona.ttf');
}
@font-face {
	font-family: $salome;
	src: url('../fonts/Salome.otf');
}

@font-face {
	font-family: $black_friday;
	src: url('../fonts/CFMODGROTESK-REGEXTEXP.OTF');
}