@import "variables";
@import "mixins";
@import "fonts";

@import "../node_modules/bootstrap/scss/bootstrap";

@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";

@import "../node_modules/owl.carousel/src/scss/owl.carousel";
@import "../node_modules/sweet-dropdown/dist/min/jquery.sweet-dropdown.min.css";
/*@import "../node_modules/mmenu-light/dist/mmenu-light.css";*/
@import '../node_modules/hc-offcanvas-nav/src/scss/core';
@import '../node_modules/hc-offcanvas-nav/src/scss/toggle';
@import '../node_modules/hc-offcanvas-nav/src/scss/theme-default';

/* HEADER */
@import "./components/header";

/* MODULES */
@import "./components/theme";

/* FOOTER */
@import "./components/footer";

/* SELECTIVE RESPONSIVE */
@import "responsive";
