#checkout-cart {
	a {
		color: #444;
	}
    h3, h2 {
        font-family: $astyB;
        font-size: 20px;
    }
    h4 {
        font-family: $astyM;
        font-size: 18px;
        border-bottom: 4px solid $primary-color;
        margin-bottom: 1em;
        padding-bottom: .5em;
    }
    .table-responsive {
        font-size: 14px;
        a {
            font-size: 13px;
        }
    }
    .alert {
        position: relative;
    }
	.input-group {
		.form-control[name^="quantity"] {
			border: 1px solid $primary-color;
			border-radius: 6px;
		}
	}
	.btn-update {
		margin-left: 5px;
		margin-right: 2px;
		height: 38px;
        z-index: 1;

        @include media-breakpoint-down(md) { 
            margin: 0;
            max-width: 35px;
            font-size: 12px;
        }
	}
    .btn-remove {
        z-index: 1;
    }
	.btn-primary {
		min-height: 38px;
	}
	input[type="text"],
	select {
		border-radius: 6px;
		border: 1px solid $primary-color;
    }    
    .buttons {
        display: flex;
        justify-content: space-between;
    }
    .cart {
        margin-bottom: 2.5rem;
        &__product {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin: 1rem 0 0.5rem;
            border-top: 1px solid #dde2e6;
            border-bottom: 1px solid rgba(221, 226, 230, 0.5);
            padding: 10px 0px;        
            &__image {
                -webkit-box-flex: 0;
                flex: 0 0 25%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
            }        
            &__details {
                -webkit-box-flex: 0;
                flex: 0 0 75%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-direction: column;        
                &__info {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;        
                    &__main {
                        margin-bottom: 0.75rem;
                    }        
                    &__price {
                        font-size: 0.85rem;
                    }        
                    &__name {
                        color: $black;
                        font-size: 1rem;
                    }        
                    &__span {
                        font-size: 0.65rem;
                    }
                }        
                &__actions {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;        
                    &__input {
                        width: 80px;
                    }        
                    button {
                        margin: 0px 0.5rem;
                    }
                }
            }
        } 
    }
}
.cart-aside {
    height: 100%;
    width: 28%;
    position: fixed;
    z-index: 20100;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background-color: $lightpink;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all .25s ease-in-out;
    border: 2px solid $lightpink;
    @include thin-scollbar;
    &.show {
        transform: translateX(0);
    }
    .fas {
        font-size: 20px;
    }
    &__header {
        padding: 4em 2em 2em;
        display: flex;
        flex-flow: row wrap;
        h2 {
            color: black;
            font-size: 14px;
            font-family: $astyB;
            line-height: 17px;
            &:after {
                @extend %content;
                background: $lightbrown;
                height: 2px;
                width: 85%;
                margin-top: 1.8em;
                left: 2rem;
            }
        }
    }
    &__bottom {
        display: none;
        .upper {
            position: absolute;
            bottom: 0;
        }
    }
    .cart {
        ul {
            padding-left: 7.94%;
            padding-right: 7.94%;
            color: $black;
            font-size: 15px;
            font-family: $astyM;

            @include media-breakpoint-down(md) {
                padding-right: 5%;
                padding-left: 5%;
            }
        }
    }
    .btn-remove {
        font-size: 10px;
        &:hover {
            border: 1px solid $black;
        }
        .fas {
            font-size: 12px;
            vertical-align: inherit;
        }
    }
    .btn-primary, .btn-default {
        font-size: 15px;
    }
    .cart_product_row {
        a {
            font-family: $asty;
            font-size: 14px;
        }
    }
}
.product_counter {
    position: absolute;
    top: 0;
    right: 15px;
    background: $primary-color;
    color: $black;
    font-family: $astyB;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    padding: 5px;
    line-height: 1;
}
.bgcheckout {
    overflow-x: hidden;
    overflow-y: auto;
}