@mixin keyframe ($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin animation ($delay, $duration, $animation, $iteration, $direction: normal, $fillmode: forwards) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: $fillmode;
    -webkit-animation-direction: $direction;
    -webkit-animation-iteration-coun: $iteration;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: $fillmode;
    -moz-animation-direction: $direction;
    -moz-animation-iteration-coun: $iteration;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: $fillmode;
    animation-direction: $direction;
    animation-iteration-count: $iteration;
}

@mixin thin-scollbar() {
    ::-webkit-scrollbar {
        width: 12.5px;
    }
      
    ::-webkit-scrollbar-track {
        border-radius: 20px;
    }
    
    ::-webkit-scrollbar-thumb {
        background: rgb(164, 163, 163);
        border-radius: 10px;
    }
}

@include keyframe(spin) {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes anim-1 {
    50% {
        opacity: 0;
        -webkit-transform: translate3d(1em, 0, 0);
        transform: translate3d(1em, 0, 0);
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(-1em, -40%, 0);
        transform: translate3d(-1em, -40%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, -40%, 0);
        transform: translate3d(0, -40%, 0);
    }
}

@keyframes anim-1 {
    50% {
        opacity: 0;
        -webkit-transform: translate3d(1em, 0, 0);
        transform: translate3d(1em, 0, 0);
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(-1em, -40%, 0);
        transform: translate3d(-1em, -40%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, -40%, 0);
        transform: translate3d(0, -40%, 0);
    }
}