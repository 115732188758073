.product-layout {
	.product-thumb {
		.prdlist {
			width: 65%;
			.title-product {
				font-family: $astyM;
				font-size: 16px;
				/* limit prd name */
				vertical-align: middle;
				text-overflow: ellipsis;
				overflow: hidden;
				@include media-breakpoint-down(md) {
					font-size: 15px;
				}
				&>a {
					color: $black;
					white-space: nowrap;
				}
			}
		}
		.button-group {
			text-align: center;
			border: 2px solid $black;
			border-radius: 30px;			
			.add-to-cart, .price {
				font-family: $astyB;
				font-size: 15px;
			}
			.price-new {
				font-size: 20px;
			}
			.price-old {
				text-decoration: line-through;
				text-decoration-color: red;
				font-size: 15px;
			}
			@include media-breakpoint-down(md) {
				border-width: 1px;
				.add-to-cart, .price {
					font-size: 13px;
				}
				.price-new {
					font-size: 15px;
				}
				.price-old {
					font-size: 13px;
				}
				.cartbtn {
					padding: 5px;
				}
			}
			&:hover {
				background-color: $black;
				color: $white;
				.cartbtn {
					a {
						color: $white;
					}
					&:focus {
						border: 0;
					}
				}
			}
		}
	}
}

.xzoom-container, .thumbnails-container {
    .xzoom {
        box-shadow: none;
    }
    .xzoom-thumbs, .main-thumbs {
        margin: 0 auto;
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
        justify-content: center;
        cursor: grab;        
        a {
            border: 0;
            flex: 0 0 10%;
            @include media-breakpoint-down(sm) {
            	flex: 0 0 14%;
            }
        }
        .xzoom-gallery, .thumbs-gallery {
            margin: 20px auto;
            padding: 0;
            border: 0;
            box-shadow: none;
            outline: none;
            max-width: 85%;
            border: 2px solid $silver;
            @include media-breakpoint-down(sm) {
            	margin: 10px auto;
            }
        }
		&::-webkit-scrollbar {
		 	width: 3px;
		 	height: 5px;
		}
		&::-webkit-scrollbar-track {
		  	box-shadow: inset 0 0 5px $lightpink;
		  	border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb {
		  	background: $lightpink;
		  	border-radius: 10px;
		}
    }
}
.related-row {
	.title {
		font-size: 40px;
		color: $black;
	}
}
.product_details_col {
	.product_title {
		font-size: 24px;
		font-family: $astyB;
		text-align: center;
		margin: 0 auto 1em;
		max-width: 80%;
		@include media-breakpoint-down(lg) {
			margin-top: 1em;
		}
		@include media-breakpoint-down(sm) {
			font-size: 18px;
			max-width: 100%;
		}
	}
}
.product-buttons-wrap {
	.nav-fill {
		text-align: center;
		border: 2px solid $black;
		border-radius: 30px;
		padding: 12px 2em;
		margin-bottom: 1em;
		align-items: center;
		font: {
			size: 15px;
			weight: 600;
		}
		&.availability {
			.availability_stock {
				margin-right: 1em;
				&.instock {
					color: #128405;
				}
				&.preorder {
					color: #d32b25;
				}
				&.outofstock {
					color: #b41b14;
				}
			}
		}
		&.wishlist {
			.btn-wishlist {
				border: 0;
				background-color: transparent;
				&:hover {
					background-color: transparent;
					border: 0;
				}
				img {
					margin-right: 2em;
				}
			}
		}
		&.qty {
			padding: 4px 2em;
			.input-group {
				width: 50%;
				width: fit-content;
				float: right;
				.btn-primary {
					background-color: transparent;
					border: 0;
					font-weight: 600;
					color: $black;
					z-index: 1;
				}
				#input-quantity {
					font-weight: 600;
					text-align: center;
					border: 0;
				}
			}
		}		
	}
	.add_to_cart_wrapper {		
		.btncart {
			text-align: center;
			border: 2px solid $black;
			border-radius: 30px;
			padding: 12px 2em;
			background-color: $black;
			color: $white;
			font: {
				size: 15px;
				weight: 600;
			}
			.nav-fill {
				margin: 0;
				padding: 0;
				border-radius: 0;
				border: 0;
			}

			&:hover {
				background-color: $white;
				color: $black;
				.btncart {
					color: $black;
				}
			}
		}
		.price-new {
			font-size: 20px;
		}
		.price-old {
			text-decoration: line-through;
			text-decoration-color: red;
			font-size: 15px;
		}
	}
}
.pds {
    a {
        &:hover,
        &:visited {
            text-decoration: none;
        }
        &.preview {
            display: inline-block;
            &.pds-current {
                overflow: hidden;
                border-radius: 40px;
                box-shadow: 0px 0px 0px 3px $secondary-color;
                display: inline-block;
            }
        }
        &.pds-current {
            overflow: hidden;
            box-shadow: 0px 0px 0px 3px $secondary-color;
            display: inline-block;
            border-radius: 50px;
            img {
                overflow: hidden;
            }
        }
    }
    &--row {
    	min-height: 120px;
    	max-height: 120px;
    	overflow-y: auto;
	    overflow-x: hidden;	    
	    margin: 12px auto;
	    &::-webkit-scrollbar {
	    	width: 10px;
		}
		&::-webkit-scrollbar-track {
		  	box-shadow: inset 0 0 5px #fed9db; 
		  	border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb {
		  	background: #fed9db; 
		  	border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb:hover {
		  	background: #fed9db; 
		}
    }
    .checked {
        img {
            max-width: 50px;
            border-radius: 50%;
            border: 1px solid $secondary-color;
        }
    }
}
.tabs_row {
	.tabs-title {
		font-size: 32px;
		@include media-breakpoint-down(md) {
			font-size: 26px;
		}
	}
	.nav-products {
		.nav-link {
			font-size: 17px;
			color: #C8C8C8;
			color: #000;
			text-decoration: underline;

			&.active {
				background-color: transparent;
				color: $black;
			}
		}
	}
    @include media-breakpoint-down(md) {
        .nav-products {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            max-width: 100%;
            overflow: scroll;
            padding-bottom: 10px;
            .nav-item { 
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
            }
        }
    }
	.tab-products { 
		.tab-pane {
			font-size: 15px;
			.row--tabs {
				.info {
					font-size: 15px;
					padding: 15px 20px;
					min-height: 580px;
					max-height: 580px;
					overflow-y: auto;
					overflow-x: hidden;
					position: relative;
					.top, .bottom  {
						text-align: center;
						position: absolute;
						letter-spacing: 9.4vw;
						margin: 0 auto;
						font: {
							size: 2em;
							family: $adam;
						}
						@include media-breakpoint-down(md) {
							width: 90%;
						}
					}
					.top {
						top: 0;
					}
					.bottom {
						bottom: 0;
					}
					.desc {
						line-height: 2;
						position: absolute;
						top: 45%;
						bottom: 0;
						right: 0;
						left: 33%;
						max-height: 220px;
						min-height: 220px;
						overflow-y: auto;
						overflow-x: hidden;
						transform: translate(-25%,-33%);
						&::-webkit-scrollbar {
						 	width: 10px;
						}
						&::-webkit-scrollbar-track {
						  	box-shadow: inset 0 0 5px $lightpink; 
						  	border-radius: 10px;
						}
						&::-webkit-scrollbar-thumb {
						  	background: $lightpink; 
						  	border-radius: 10px;
						}
						&::-webkit-scrollbar-thumb:hover {
						  	background: $lightpink; 
						}
					}
				}
				&.row--tab1, &.row--tab4, &.row--tab7 {
					.info {
						background-color: $lightpink;
						.top, .bottom {
							color: $white;
							color: #6c5052;
						}
						.desc {
							&::-webkit-scrollbar {
							 	width: 10px;
							}
							&::-webkit-scrollbar-track {
							  	box-shadow: inset 0 0 5px #fed9db; 
							  	border-radius: 10px;
							}
							&::-webkit-scrollbar-thumb {
							  	background: #fed9db; 
							  	border-radius: 10px;
							}
							&::-webkit-scrollbar-thumb:hover {
							  	background: #fed9db; 
							}
						}
					}
				}
				&.row--tab2, &.row--tab5, &.row--tab8 {
					.info {
						background-color: $primary-color;
						.top, .bottom {
							color: #d6b0b3;
							color: #6c5052;
						}						
					}
				}
				&.row--tab3, &.row--tab6, &.row--tab9 {
					.info {
						background-color: $contactpink;
						.top, .bottom {
							color: #f3c8cc;
							color: #6c5052;
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-down(xl) {
	.product_image_col {
		margin: 0 auto;
	}
}