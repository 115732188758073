.header {
    &--wrap {
    	&--info {
    		color: $white;
    		background-color: $primary-color;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 999;
    		p {
                color: #434343;
    			font: {
    				size: 14px;
    				family: $astyM;
    			}
    		}
            @include media-breakpoint-down(md) {
                /*display: none;*/
            }
    	}
        &--main {
            margin-top: 3em;
            @include media-breakpoint-down(lg) {
                margin-top: 4em;
            }
        }
    	&--menu {
            .col-menu {
                .menu.icon {
                    color: #000;
                    position: absolute;
                    margin-left: 2px;
                    width: 24px;
                    height: 2px;
                    background-color: currentColor;
                }
    
                .menu.icon:before {
                    content: '';
                    position: absolute;
                    top: -7px;
                    left: 0;
                    width: 24px;
                    height: 2px;
                    background-color: currentColor;
                }
    
                .menu.icon:after {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 0;
                    width: 24px;
                    height: 2px;
                    background-color: currentColor;
                }
            }
    		.nav-link {
				color: $black;                
				font: {
    				size: 14px;
    				family: $astyB;
    			}
                &:hover{
                    cursor: pointer;
                }
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
			}
    		.extra-nav {    			
    			.dropdown-menu {
    				ul {
    					box-shadow: none;
    				}
    			}
    		}
    	}
    }
}
.common-home {
    .header {
        &--wrap {
            &--info {
                color: $black;
                background-color: $silver;
            }
        }
    }
}

.container--wrapper {
    /*max-width: 1595px;*/
	/*max-width: 1695px;*/
    max-width: 1717px;
}


.search-desktop{
    .nav-link{
        padding:0;
        button{
            
            font-size:14px;
            &:focus{
                outline:none;
                border:none;
            }
        }
    }
}


@import "search";
@import "cart";
@import "menu";