#information-information {
    .container-info {
        &--desc {
            h3 {
                color: $black;
                font: {
                    size: 24px;
                    family: $astyM;
                }
                &:before {
                    @extend %content;
                    counter-increment: section;
                    content: counter(section);
                    background-image: url('../../dist/image/info/shape.png');
                    width: 57px;
                    height: 68px;
                    margin: {
                        top: -1rem;
                        left: -2em;
                    }
                    padding: {
                        top: 12px;
                        left: 15px;
                    }
                    font: {
                        size: 38px;
                        family: $astyM;
                    }
                }
            }
            p, ul, li, a, td {
                color: $lightbrown;
                color: #6c5052;
                font-size: 16px;
            }
            img {
                max-width: 100%;
                height: auto;
                display: block;
            }
        }
    }
    .grid {
        position: relative;
        clear: both;
        text-align: center;
        figure {
            position: relative;
            overflow: hidden;
            text-align: center;
            cursor: pointer;
            img {
                opacity: 0.8;
            }
            figcaption {
                backface-visibility: hidden;
                &::before,
                &::after {
                    pointer-events: none;
                }
            }
            p {
                letter-spacing: 1px;
                margin: 0;
                font-size: 68.5%;
            }
            &.effect-steve {
                z-index: auto;
                overflow: visible;
                padding: 2em;
                &:before,                
                h2:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    content: "";
                    transition: opacity 0.35s;
                }
                &:before {
                    box-shadow: 0 3px 42px rgba(0, 0, 0, 0.2);
                    opacity: 0;
                }
                figcaption {
                    z-index: 1;
                }
                img {
                    margin-top: 10px;
                    opacity: 1;
                    transition: transform 0.35s;
                    transform: perspective(1000px) translate3d(0, 0, 0);
                }
                h2 {
                    position: relative;
                    margin-top: 10px;
                    color: $black;
                    font: {
                        size: 17px;
                        family: $astyB;
                    }
                }
                p {
                    margin-top: 1em;
                    padding: 0.5em;
                    font-weight: 400;
                    opacity: 0;
                    transition: opacity 0.35s, transform 0.35s;
                    transform: scale3d(0.9, 0.9, 1);
                }
                &:hover {
                    &:before {
                        opacity: 1;
                    }
                    img {
                        transform: perspective(1000px) translate3d(0, 0, 21px);
                    }
                    h2:before {
                        opacity: 0;
                    }
                    p {
                        opacity: 1;
                        transform: scale3d(1, 1, 1);
                    }
                }
            }
        }
    }
}