.info-upper-part{
    position: fixed;
    left: 0;
    top: 20%;
    width: 100%;
    z-index: 1;
    h1 {
        font-size: 15em;
        font-family: $astyB;
        color: $black;
        display: block;
        text-align: center;        
    }
    @include media-breakpoint-down(lg) {
        position: relative;
        h1 {
            font-size: 4em;
            margin-top: .5em;
        }
    }
}
.image-row{
    position: relative;
    margin-top: 15rem;
    .words-image {
        position: absolute;
        top: -100px;
        z-index: 4;
        display: flex;
        justify-content: center;
    }
    .upper-image{
        display: flex;
        z-index: 3;
        justify-content: center;
        position: relative;
        .blusher {
            position: relative;
            display: inline-block;
            &:before {
                content: "";
                position: absolute;
                width: 491px;
                height: 491px;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background: $primary-color;
                border-radius: 400px;
                z-index: -1;
                right: -66%;
                top: 11%;
            }
        }     
        @include media-breakpoint-down(md) {
            .blusher {
                &:before {
                    position: unset;
                    right: 0;
                    width: 0;
                    height: 0;
                }
            }
            img {
                max-width: 300px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        margin-top: 2rem;
        background-color: #FED8DB;
        .words-image {
            top: 0;
            img {
                max-width: 250px;
            }
        }
        .upper-image{
            top: -2em;
        }
    }
}
.upper-text-info {
    background: $aboutgrey;
    margin-top: 80px;
    position: relative;
    z-index: 2;
    padding-top: 40px;
    &:after {
        content: "";
        width: 687px;
        height: 687px;
        -webkit-filter: blur(5px);
        filter: blur(5px);
        position: absolute;
        background: #fed8db;
        border-radius: 800px;
        filter: blur(65px);
        top: -158%;
        left: 13%;
        @include media-breakpoint-down(lg) {
            width: 495px;
            height: 495px;
        }
        @include media-breakpoint-down(md) {
            width: 0;
            height: 0;
        }
    }
    h2 {
        font-size: 43px;
        color: $black;
        font-family: $asty;
        position: relative;
        z-index: 9;
    }
    p {
        font-size:23px;
        color: $lightbrown;
        color: #6c5052;
        font-family: $asty;
        position: relative;
        z-index: 9;
    }
    @include media-breakpoint-down(md) {
        margin-top: 1em;
        padding-left: 14px;
        padding-right: 14px;
        h2 {
            font-size: 28px;
            margin-bottom: .5em;
            z-index: auto;
        }
        p {
            font-size: 16px;
            br {
                display: none;
            }
        }
    }
}
.ceo-info {
    padding-top: 9rem;
    position: relative;
    background: $aboutgrey;
    z-index: 4;
    .ceo-image {
        position:relative ;
        &:after {
            content: "";
            position: absolute;
            width: 800px;
            height: 800px;
            left: -75%;
            bottom: -48%;
            background: #E1B6BB;
            border-radius: 900px;
            filter: blur(50px);
            opacity: 0.6;
        }
        .image-wrapper {
            position: relative;
            img{
                position: relative;
                z-index: 6;
            }
            &:after{
                content: "";
                width: 265px;
                height: 265px;
                border: 3px solid $white;
                position: absolute;
                right: -146px;
                top: -100px;
                z-index: -1;
                border-radius: 900px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding-top: 4rem;
        z-index: auto;
        .ceo-image {
            &:after {
                width: 0;
                height: 0;
                z-index: auto;
                position: unset;
                display: none;
            }
            .image-wrapper {
                &:after {
                    width: 0;
                    height: 0;
                    z-index: auto;
                    position: unset;
                    display: none;
                }
                img {
                    z-index: auto;
                    max-width: 300px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    }
}
.ceo-text{
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    padding: 5rem 3rem;
    p {
        color: $lightbrown;
        color: #6c5052;
        font-family: $asty;
        font-size: 16px;
        margin-bottom: 0;
    }
    h3 {
        color: $black;
        font-family: $astyB;
        font-size: 26px;    
    }
    @include media-breakpoint-down(md) { 
        padding: 2rem 20px;
        h3 {
            margin-top: 1em;
        }
    }
}
.brands-info{
    padding-top: 9rem;
    position: relative;
    background: $aboutgrey;
    z-index: 2;
    h2 {
        color: $black;
        font-family: $astyB;
        font-size: 49px;   
    }
    .brands-text {
        padding-left: 5rem;
        padding-right: 4rem;
        p {
            color: $lightbrown;
            color: #6c5052;
            font-family: $asty;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(md) {  
        padding-top: 0;
        .brands-text { 
            padding-left: 20px;
            padding-right: 20px; 
        }
    }
}
.beauty-first {
    padding-top: 4rem;
    position: relative;
    background: $aboutgrey;
    padding-left: 5rem;
    z-index: 2;
    h2 {
        color: $black;
        font-family: $astyB;
        font-size: 61px;  
    }
    .beauty-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        p {
            color: $lightbrown;
            color: #6c5052;
            font-family: $asty;
            font-size: 16px;
            padding-right: 6rem;
        }
    }
    @include media-breakpoint-down(md) {  
        padding-left: 0;
        h2 {
            font-size: 38px;
            br {
                display: none;
            }
        }
        .beauty-text {
            padding-left: 20px;
            padding-right: 20px;
            p {
                padding-right: 0;
                br {
                    display: none;
                }
            }
        }
    }
}
.makeupers{
    padding-top: 9rem;
    padding-bottom: 12rem;
    position: relative;
    background: $aboutgrey;
    z-index: 2;
    position: relative;
    &:after {
        content: "";
        width: 81vw;
        position: absolute;
        height: 60vw;
        background: $primary-color;
        border-radius: 2000px;
        bottom: -44vw;
        filter: blur(181px);
        margin: 0 auto;
        left: 0;
        right: 0;
    }
    h2 {
        color: $black;
        font-family: $astyB;
        font-size: 69px;  
        text-align: center;      
    }
    @include media-breakpoint-down(md) {  
        padding-top: 4rem;
        padding-bottom: 4rem;
        h2 {
            font-size: 38px;
            br {
                display: none;
            }
        }
    }
}
.crawler{    
    padding-top: 9rem;
    z-index: 2;
    position: relative;
    background: $aboutgrey;
    @include media-breakpoint-down(md) {  
        padding-top: 0;
    }
}
.scroll-text {
    font-size: 110px;
    font-family: $astyB;
    @include media-breakpoint-down(md) {  
        font-size: 80px;
    }
}