@media only screen and (max-width: 1679px) {
	.cart-aside, .login-aside {
		width: 32%;
	}
	.header {
	    &--wrap {
	    	&--info {
	    		.espa {
	    			top: 2em;
	    		}
	    	}
	    }
	}
} 
@media only screen and (max-width: 1425px) {
	.cart-aside, .login-aside {
		width: 38%;
	}
}
@media only screen and (max-width: 1366px) {
	.info, .instagram {
		&--wrapper {
			.info-title, .instagram-title {
				font-size: 40px;
			}
		}
	}
}
@media only screen and (max-width: 1199px) {
	.cart-aside, .login-aside {
		width: 47%;
	}
}
@media only screen and (max-width: 1035px) {
	.cart-aside, .login-aside {
		width: 58%;
	}
}
@media only screen and (max-width: 845px) {
	.header {
		&--wrap {
			&--main {
				margin-top: 4em;
			}
		}
	}
}
@media only screen and (max-width: 825px) {
	.cart-aside, .login-aside {
		width: 65%;
	}
}
@media only screen and (max-width: 767px) {
	.info, .instagram {
		&--wrapper {
			.info-title, .instagram-title {
				font-size: 30px;
			}
		}
	}
	.caption {
		.wshlist {
			display: none;
		}
	}
	.header {
		&--wrap {
			&--main {
				margin-top: 5em;
			
			}
			&--info {
	    		.espa {
	    			top: 4em;
	    		}
	    	}
		}			
	}
}
@media only screen and (max-width: 665px) {
	.cart-aside, .login-aside {
		width: 75%;
	}
}
@media only screen and (max-width: 480px) { 
	.cart-aside, .login-aside {
		width: 92%;
	}

	.row-slider{
		display:none;
	}


	.header {
		&--wrap {
			&--main {
				margin-top: 6em;

			}
			&--info {
	    		.espa {
	    			top: 6em;
	    		}
	    	}
		}
	}
}

@media screen and (min-width:1040px) and (max-width:1600px){
	.videobanner{

  
		.video-banner-wrapper{

			iframe{
				top: -130px;
				height:610px;
			}
		}
	}
}


@media screen and (max-width:768px){
	.videobanner{

  
		.video-banner-wrapper{
			position: relative;
			overflow: unset;
			width: 100%;
			padding-top: unset;
			height:inherit;
			iframe{
				position: unset;

				width: 100%;
				height: 631px;
				margin:0 auto; 
			}
		}
	}
	
}

// BFRIDAY


@media only screen and (max-width: 1679px){
.header--wrap--info .espa {
    top: 4em;
}
}
/*

@media screen and (max-width:576px){

	.black-friday-sticker {
	
		width: 100px;
		height: 100px;

		bottom:45%;

		bottom:50%;

		a{

			p{
				margin-bottom:0;
				text-align:center;
				font-size:10px;
			}
			.text-bold{
				font-family: $astyEB;
			}
			.text-norm{
				font-family: $astyM;
			}
		}
		img{
			filter: invert(1);
			max-width:40px;
		}
	
	
	}

	.blackfriday-top .explore-friday{
		display:none;
	}

	.header--wrap--info .espa {
		top: 6em;
	}

}


@media screen and (max-width:440px){
	.header--wrap--info .espa {
		top: 8em;
	}
}

@media screen and (min-width:577px) and (max-width:768px){
	.black-friday-sticker {
	
		width: 150px;
		height: 150px;

		a{

			p{
				margin-bottom:0;
				text-align:center;
				font-size:12px;
			}
			.text-bold{
				font-family: $astyEB;
			}
			.text-norm{
				font-family: $astyM;
			}
		}
		img{
			filter: invert(1);
			max-width:40px;
		}
	
	
	}

	.blackfriday-top .explore-friday{
		display:none;
	}

	
	.blackfriday-top p {
		font-size: 11px;
		span{
			font-size:20px;
		}
	}
}


@media screen and (min-width:769px) and (max-width:992px){
	.black-friday-sticker {
	
		width: 150px;
		height: 150px;

		a{

			p{
				margin-bottom:0;
				text-align:center;
				font-size:12px;
			}
			.text-bold{
				font-family: $astyEB;
			}
			.text-norm{
				font-family: $astyM;
			}
		}
		img{
			filter: invert(1);
			max-width:40px;
		}
	
	
	}

	.blackfriday-top .explore-friday{
		display:none;
	}


}

@media screen and (min-width:993px) and (max-width:1200px){
	.black-friday-sticker {
	
		width: 150px;
		height: 150px;

		a{

			p{
				margin-bottom:0;
				text-align:center;
				font-size:12px;
			}
			.text-bold{
				font-family: $astyEB;
			}
			.text-norm{
				font-family: $astyM;
			}
		}
		img{
			filter: invert(1);
			max-width:40px;
		}
	
	
	}

	.blackfriday-top .explore-friday .explore{
		font-size:12px;
		padding:10px 14px;
	
	}

	.blackfriday-top a, .blackfriday-top p{
		font-size:12px;
		letter-spacing: 8px;
	}


	.blackfriday-top{
		.explore-friday{
			top:13px;
			img{
				max-width:30px;
			}
		}
	}
}

@media screen and (min-width:1201px) and (max-width:1300px){


	.blackfriday-top .explore-friday .explore{
		font-size:12px;
		padding:10px 14px;
	
	}

	.blackfriday-top a, .blackfriday-top p{
		font-size:20px;
		letter-spacing: 10px;
	}


	.blackfriday-top{
		.explore-friday{
			top:13px;
			img{
				max-width:30px;
			}
		}
	}
}

@media screen and (min-width:1301px) and (max-width:1400px){





	.blackfriday-top{
		.explore-friday{
			top:-4px;

		}
	}
}
 */

 @media screen and (max-width:992px){
	.header--wrap--info .espa {
		top: unset!important;
		bottom: 6em;
		position: fixed!important;
	}

 }
 
 #payment_address_fax_input,#payment_address_company_input{
 display:none!important;
 }


 @media screen and (max-width:768px){
	.extra-nav{
		padding:0;
		ul{
			align-items:center;
		}
		.mobile-search{
			flex:0 0 auto;
			width:50%;
			margin-right:10px;
		}
		.searchmodal{
			padding:0;
		}
	}
 }

@media only screen and (max-width: 414px) {}

@media only screen and (max-width: 375px) {}