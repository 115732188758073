.search--wrapper {
    bottom: 1em;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    z-index: 1;
    width: fit-content;

    .searchmodal {
        overflow: hidden;
        border-radius: 100%;
        background-color: $primary-color;
        height: 50px;
        width: 50px;
    }
}
#search {
    .title-search {
        font-size: 27px;
        font-family: $astyB;
        margin: 0 auto;
        width: 100%;
        text-align: center;
    }
    label {
        font-size: xx-small;
        color: $white;
        margin: 0;
        height: 0;
    }
    .input-group {
        margin: 0 auto;
        max-width: 70%;
        border-bottom: 2px solid $lightbrown;
        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }
    .form-control {
        border: none;
        z-index: 100;
        font-family: $astyB;
        font-size: 18px;
        height: 60px;
        transition: width 0.3s;
        letter-spacing: 1px;
        background-color: #EEF9F7;

        &:active,
        &:focus {
            box-shadow: none;
        }
    }
    .popular-categories {
        .title-popular {
            text-align: center;
            font-size: 16px;
            font-family: $astyB;
            color: $black;
        }
        .nav-pills {
            .nav-item {
                margin: auto 1em;
            }
        }
        @include media-breakpoint-down(md) { 
            .nav-pills {
                display: block;
                .nav-item {
                    display: contents;
                    margin: 0;
                }
            }
            img {
                max-width: 120px;
            }
        }
    }
}
.modal.fade.show #modalSearch {
    /*bottom: 0;*/
    opacity: 1;
}
#modalSearch {
    position: relative;
    bottom: -12.4em;
    opacity: 0;
    transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
    max-width: 80%;
    width: 100%;
    background: rgba(255, 255, 255, 0.64);
    .modal-content {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        background-color: #EEF9F7;
        outline: none;
        padding: 2em;
    }
    @include media-breakpoint-down(md) { 
        margin: 0;
        max-width: 100%;
        .modal-content {
            padding: 15px;
            text-align: center;
        }
    }
    .modal-header {
        border: 0;
        min-height: 65px;
    }
    .close {
        font-size: 3em;
        color: $black;
        opacity: 1;
    }
}