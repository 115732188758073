#menu {
    .nav,
    .collapse,
    .dropdown {
        position: static;
    }
    .nav {
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .nav-item {
        .dropdown-menu {
            width: 100%;
            background: $white;
            margin-left: 0 !important;
            display: block;
            visibility: hidden;
            z-index: 1000;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
            margin-top: 0;
        }
        &:hover {
            .dropdown-menu {
                transition: 0.3s;
                opacity: 1;
                visibility: visible;
                top: 100%;
                transform: rotateX(0);
            }
        }
        .nav-link {
            padding: 0.5rem 1rem;
            color: $black;
            border-radius: 0;
            text-transform: uppercase;
            font: {
                size: 17px;
                weight: 600;
            }
            &.active {
                color: $white;
            }
        }
    }
    .dropdown-inner {
        display: table;
        &:focus,
        &:hover {
            background-color: transparent;
        }
        .grid-item {
            border-right: 2px solid $primary-color;
        }
        ul {
            display: table-cell;
        }
        a {
            min-width: 160px;
            display: block;
            padding: 15px 20px;
            clear: both;
            line-height: 20px;
            font-size: 17px;
        }
        .second-child-ul {
            .CHRISTMAS {
                display: none;
            }
            li a {
                text-transform: uppercase;
                font-weight: 600;
            }
        }
        .third-child-ul li a {
            text-transform: capitalize;
            padding: 15px 20px;
            font-weight: 400;
            font-size: 16px;
        }
    }
    .dropdown-toggle::after {
        display: none; 
    }
}

.hc-nav-trigger.hc-nav-1 {
    position: relative;
    top: 0;
    @include media-breakpoint-down(md) {
        z-index: 99;
    }
}
.hc-offcanvas-nav {
    .nav-container, .nav-wrapper, ul {
        background-color: #fff;
    }
    .nav-back a, .nav-item-link, li.nav-close a {
        color: #000;
        font-family: $astyB;
        font-size: 18px;
    }
    .nav-back a, .nav-content>.nav-close:first-child a, .nav-title+.nav-close a.has-label, li.nav-close a {
        background: #fff;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
    }
    .nav-close-button span::after, .nav-close-button span::before {
        border-top: 2px solid #000;
        border-left: 2px solid #000;
    }
    &.hc-nav-1 .nav-container {
        min-width: 50%;
    }
    .nav-back span::before, .nav-next span::before {
        border-top: 2px solid #000;
        border-left: 2px solid #000;
    }
    .nav-wrapper>.nav-content>ul:first-of-type>li:first-child:not(.nav-back):not(.nav-close)>.nav-item-wrapper>.nav-item-link {
        border-top: 1px solid #c9c9c9;
    }
    .nav-back a, .nav-item-link, li.nav-close a {
        border-bottom: 1px solid #c9c9c9;
    }
    a.nav-next {
        border-left: 1px solid #fff;
        border-bottom: 1px solid #c9c9c9;
    }
    &:not(.touch-device) li:not(.nav-item-custom) a:not([disabled]):hover {
        background-color: #dfdfdf;
        opacity: 0.8;
    }
    .nav-content>h2 {
        font-family: $astyM;
    }
}

@media screen and (min-width: 768px) {
    #menu {
        .dropdown {
            > .dropdown-toggle:active {                
                pointer-events: none;
            }
            &:hover {
                > .dropdown-menu {
                    display: block; 
                }
                .dropdown-menu {
                    border-radius: 0;
                    display: block;
                    opacity: 1;
                    filter: alpha(opacity=100);
                    transform: translate(0, 0);
                    visibility: visible;
                    border-top: 6px solid #e2cccc;
                    padding-bottom: 15px;
                }
            }
        }
    }
}
.nav-menu {
    &--desktop {
        z-index: 10000;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        transform: translateX(-101%);
        opacity: 0;
        transition: all .33s ease-in-out;
        @include make-col(9.25);
        @include media-breakpoint-down(xxl) {
            @include make-col(11);
        }
        &.opened {
            transform: translateX(0);
            opacity: 1;
        }
        .col-left,
        .col-right {
            padding-top: 4vh;
            .col-header {
                margin-bottom: 2vh;
                color: #000;
                text-align: right;
                padding-left: 3vw;
                padding-right: 2vw;
            }
        }
        .col-left {
            position: relative;
            z-index: 10000;
            background-color: $white;
            border-right: 1px solid $silver;
            overflow-y: auto;
            max-height: 100%;
            &::-webkit-scrollbar {
                width: 6.5px;
            }              
            &::-webkit-scrollbar-track {
                border-radius: 12px;
            }            
            &::-webkit-scrollbar-thumb {
                background: rgb(164, 163, 163);
                border-radius: 10px;
            }
            h2.col-header {
                font-size: 22px;
            }
            .menu-tabs {
                direction: ltr;
                border: 0;
                .nav-item {
                    a {
                        display: block;
                        text-align: left;                        
                        color: $black;
                        margin: .3vw auto;
                        padding: {
                            left: 3vw;
                            right: 1vw;
                            top: 1vw;
                            bottom: 1vw;
                        }
                        font: {
                            size: 14px;
                            family: $astyB;
                        }
                        .btnmenu {
                            &:hover, &:focus {
                                border: 0;
                            }
                        }
                        &:hover {
                            background-color: $black;
                            color: $white;
                            .btnmenu {
                                color: $white;
                            }
                        }
                        &.active {
                            position: relative;                            

                            &::after {
                                top: 0;
                                right: 0;
                                background-color: $black;
                                content: '';
                                height: 100%;
                                width: 10px;
                                position: absolute;
                            }
                        }
                    }
                    &:last-child {
                        a.active::after {
                            width: 0;
                        }
                    }
                }
            }
        }
        .col-right {
            position: relative;
            transition: all .3s ease-out;
            transform: translateX(-101%);
            z-index: 10000 - 100;
            padding-left: 15px;
            padding-right: 15px;

            &.opened {
                transform: translateX(0);
            }

            .icon {
                cursor: pointer;
            }
        }

        .grid {
            .grid-item {
                padding: 2em 1.5em 1em;
                width: 25%;
                ul {
                    h4 {
                        background-color: $white;
                        color: $black;
                        margin-bottom: 0;
                        font-size: 16px;

                        a {
                            font-family: $astyM;                            
                            color: $black;
                        }
                    }
                    h5 {
                        font-size: 14px;
                        margin-top: 10px;
                        margin-bottom: 10px;

                        a {
                            font-family: $asty;
                        }
                    }
                    h4, h5 {
                        a {
                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-between(lg, xl) {
                .grid-sizer, .grid-item {
                    width: 45%;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .hc-offcanvas-nav {
        &.hc-nav-1 .nav-container {
            min-width: 100%;
        }
    }
}