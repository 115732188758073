.footer--wrap {
    background-color: $primary-color;
	position:relative;
	z-index: 2;
    .footer {
    	padding: 9em 2em 1em;

        @include media-breakpoint-down(lg) {
            padding-top: 3em;
        }
        @include media-breakpoint-down(md) {
            padding: 2em 1em;
        }
        @include media-breakpoint-down(sm) {
            padding-bottom: 1em;
        }
    	.category--row {
    		.list-categories {
    			line-height: 2.5;
    			font: {
    				size: 37px;
    				family: $astyM;
    			}                
    		}
    	}
    	.contact--row {
    		.list-contact {
    			font-size: 22px;
    		}
    	}
    	.information--row {
    		.list-information {
    			line-height: 2.2;
    			font: {
    				size: 15px;
    				family: $astyM;
    			}                
    		}
            @include media-breakpoint-down(md) {
                margin-top: 1em;
                .list-information { margin-bottom: 0; }
            }
    	}
    	.newsletter--row {
    		.content_box {
    			margin: 4em auto;
                @include media-breakpoint-down(md) { 
                    margin: 1em auto;
                }
    		}
    		#mc_embed_signup  {
    			font-family: $astyB;
				
    			.form-control {
    				color: $white;
    				background-color: transparent;
    				font-size: 28px;
    				padding: .375rem 0;    				
    				border: {
    					radius: 0;
    					top: 0;
    					left: 0;
    					right: 0;
    					bottom: 2px solid $white;
    				}
    				&:focus {
    					border-color: $lightbrown;
    				}
    				&::-webkit-input-placeholder {
                        color: $white;
                    }
                    &::-moz-placeholder {
                        color: $white;
                    }
                    &:-ms-input-placeholder {
                        color: $white;
                    }
                    &:-moz-placeholder {
                        color: $white;
                    }
    			}
    			div.mce_inline_error {
    				font: {
						size: 14px;
						family: $asty;
					}
    			}
                .terms {
                    input[type="checkbox"] {
                        border-color: $lightpink;
                        width: 1.15em;
                        height: 1.15em;
                    }
                }
    			.btn-newsletter {
    				background-color: transparent;
    				color: $black;
    				padding: 0;
    				font-size: 28px;
    				border: 0;
    				&:hover {
    					color: $white;
    				}
    			}
    		}
    	}
    	.social--row {
    		.social-title {
    			font: {
	    			size: 28px;
	    			family: $astyB;
	    		}
    		}
            @include media-breakpoint-down(md) {
                .social-title {
                    font-size: 25px;
                }
                img {
                    max-width: 60px;
                }
            }
    	}
    	.copyright--row {
    		font: {
    			size: 13px;
    			family: $astyB;
    		}
    	}
    }
}
.terms {
    font: {
        size: 14px;
        family: $asty;
    }
}
.list { /* hover links */
    a:not(.nolink) {
        position: relative;
        cursor: pointer;

        &::before {
            @extend %content;                   
            width: 100%;
            height: 1px;
            background: currentColor;
            top: 100%;
            left: 0;
            pointer-events: none;

            transform-origin: 100% 50%;
            transform: scale3d(0, 1, 1);
            transition: transform 0.3s;
        }
        &:hover::before {
            transform-origin: 0% 50%;
            transform: scale3d(1, 1, 1);
        }
    }
}
@media screen and (max-width: 1475px) {
    .footer {
        &--wrap {
            .footer {
                .contact--row {
                    .list-contact {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1366px) {
    .footer {
        &--wrap {
            .footer {
                .category {
                    &--row {
                        .list-categories {
                            font-size: 33px;
                            line-height: 2;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .footer {
        &--wrap {
            .footer {
                .category {
                    &--row {
                        .list-categories {
                            margin-bottom: 0;
                            line-height: 1.5;
                            font-size: 29px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .footer {
        &--wrap {
            .footer {
                .contact--row {
                    .list-contact {
                        margin: 1em auto;
                    }
                }
                .category {
                    &--row {
                        .list-categories {
                            font-size: 27px;
                        }
                    }
                }
            }
        }
    }
}