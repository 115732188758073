.information-contact{
    background: $contactpink;
    header{
        position: relative;
        z-index: 3;
    }
}
#information-contact{
    overflow: hidden;
}
.contact-upper-part{
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    .contact-title-container{
        width: 100%;
        position: relative;
        h1 {
            font-family: $astyB;
            color:$black;
            font-size:15em;
            display:inline-block;
            &:after{
                @extend %content;
                width: 1024px;
                height: 1024px;
                background: #f0c1c5;
                border-radius: 1200px;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                top: -60%;
                right: -19%;
            }
            &:before {
                @extend %content;
                width: 880px;
                height: 880px;
                background: #f0c1c5;
                border-radius: 1200px;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                bottom: -98%;
                left: 10%;
            }
        }
    }
}
.contact-upper-text{
    margin-top: 67vh;
    position: relative;
    z-index: 3;
    text-align: center;
}
.contact-face-image{
    position: relative;
    z-index: 2;
    text-align: center;
    margin-top: -154px;
    padding-bottom: 4rem;
    background: linear-gradient(0deg, rgba(254,216,219,1) 0%, rgba(254,216,219,1) 62%, rgba(254,216,219,0.5018382352941176) 77%, rgba(254,216,219,0) 100%);
    .blurry {
        position: absolute;
        height: 100%;
        width: 100%;
        background: $contactpink;
        filter: blur(50px);
        text-align: center;
    }
    .face-image-container{
        padding-top: 62px;
        img{
            position: relative;
            z-index: 3;
        }
    }
    .lower-text{
        position: relative;
        margin-top: -20%;
    }
}
.contact-area{    
    position: relative;
    z-index: 2;
    text-align: center;
    padding: {
        top: 4rem;
        bottom: 12em;
    }
    background: {
        color:  $contactpink;
        image: url('../image/contact/icon_contact_curvy.png');
        repeat: no-repeat;
        position: center;
    }
    h2 {
        font-size: 36px;
        font-family: $astyB;
        color: $black;
    }
    p {
        margin-top: 15px;
        color: $lightbrown;
        color: #6c5052;
        font-size: 19px;
    }
}
.contact-phone{
    background: $contactpink;
    position: relative;
    z-index: 2;
    text-align: center;
    
    h2 {
        font-size: 36px;
        font-family: $astyB;
        color: $black;
    }
    h3 {
        font-size: 33px;
        color: $black;
        font-family: $asty;
    }
    p {
        margin-top: 15px;
        color: $lightbrown;
        color: #6c5052;
        font-size: 19px;
    }
}
.contact-form{
    padding-top: 40px;
    background: $contactpink;
    position: relative;
    z-index: 2;  
    padding-bottom: 15em; 

    form {
        position: relative;
    }
    .form-control {
        background: transparent;
        border: none;
        border-bottom: 2px solid $lightbrown;
        color: $lightbrown;
        color: #6c5052;
        font-size: 17px;
        font-family: $astyM;
        padding: 5px 0;
        border-radius: 0;
        margin-bottom: 20px;
 
        &::placeholder {
            color: $lightbrown;
            color: #6c5052;
            font-size: 17px;
            font-family: $astyM;   
        }
        &:focus {
            border-color: $primary-color;
            box-shadow: 0 0 0 0.1rem rgba(255, 188, 22, 0.25);
        }
    }

    .submit-button {
        position: relative;
        .submit-image {
            position: absolute;
            right: 30px;
            top: 5px;
            bottom: 0;
            z-index: 6;
        }
    }

    .blush-left-form{
        width: 40vw;
        height: 40vw;
        position: absolute;
        left: -14%;
        background: #f0c1c5;
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: .8;
        border-radius: 900px;
        top: -13%;
    }

    .blush-right-form{
        width: 50vw;
        height: 50vw;
        position: absolute;
        right: -14%;
        background: #f0c1c5;
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: .8;
        border-radius: 900px;
        bottom: -55%;
    }
    .btn {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: $white;
        border-radius: 32px;
        color: $black;
        font-size: 16px;
        font-family: $astyM;
        border: none;
        position: relative;
        padding: 10px 30px;
        position: relative;
        z-index: 5;
        &:hover {
            background: $primary-color
        }
    }
}
@media screen and (max-width: 1679px) {
    .contact-upper-part {
        .contact-title-container {
            h1 {
                font-size: 12em;
                &:after {
                    width: 770px;
                    height: 770px;
                }
                &:before {
                    width: 680px;
                    height: 680px;
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .contact-upper-part {
        .contact-title-container {
            h1 {
                font-size: 7em;
                &:after {
                    width: 424px;
                    height: 424px;
                }
                &:before {
                    width: 380px;
                    height: 380px;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .contact-form {
        padding-bottom: 0;
    }
    .contact-upper-part {
        position: relative;
        .contact-title-container {
            h1 {
                font-size: 4em;
                margin-top: .5em;
                &:after {
                    top: 2em;
                }
                &:before {
                    width: 280px;
                    height: 280px;
                }
            }
        }
    }
    .contact-upper-text {
        margin-top: 1em;
    }
    .contact-face-image .face-image-container img {
        max-width: 200px;
    }
    .contact-area {
        padding-top: 0;
        padding-bottom: 7em;
        h2 {
            font-size: 27px;
        }
        p {
            font-size: 16px;
        }
    }
    .contact-phone {
        h2 {
            font-size: 27px;
        }
        h3 {
            font-size: 24px;
        }
    }
}